.Navbar {
  display: flex;
  flex-direction: column; /* Logo ve flex kutularını dikey hizalayacağız */
  align-items: center; /* Ortalamak için */
  background-color: #142745; /* Navbar arka plan rengi */
  padding-top: 10px; /* Navbar'ın etrafındaki boşluk */
  height: fit-content;
}

.nav-items {
  display: flex; /* Flex düzeni */
  flex-direction: row; /* Küçük ekranlarda yan yana olacak şekilde ayar */
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 88vw;
}

.logo {
  height: 7vh;
  width: auto; /* Yükseklik oranını koruyarak genişliği ayarlar */
}

/* Flex kutular için düzenleme */
.flex-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  width: auto;
  margin-bottom: 20px;
}

.flex-box {
  background-color: #fff;
  padding: 20px;
  flex: 1;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Hover efekti */
.flex-box:hover {
  background-color: #f1f1f1;
}
/* Mobil menüyü 1270px'den önce göster, sonra gizle */

.navwrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navmobile {
  width: 100%;
}
.navwrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navmobile {
  display: block; /* 1200px'den küçük ekranlarda navmobile görünsün */
}
.nav-items {
  display: none; /* Varsayılan olarak gizle */
}

@media (width > 1200px) {
  .navwrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .nav-items {
    display: flex; /* 1200px ve üzeri ekranlarda nav-items görünsün */
    justify-content: space-between; /* Öğeleri yatayda eşit şekilde hizala */
    align-items: center; /* Öğeleri dikeyde ortala */
  }

  .navmobile {
    display: none; /* 1200px'den büyük ekranlarda navmobile gizlensin */
  }
}

@media (width <= 1200px) {
  .navwrapper {
    display: none;
  }
}

.nav-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
}

.nav-right .eczane .linkEczane {
  color: #fff;
  text-decoration: none;
}
.linkEczane {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.eczaneler-img {
  height: 35px;
  width: 35px;
  padding: 8px;
}
.eczane-text {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  font-size: 14px;
}
@media (max-width: 1200px) {
  .Navbar {
    padding-top: 0;
  }
}
