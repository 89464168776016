.ozel-haber-container {
  position: absolute;
  top: 3%;
  left: 3%;
  z-index: 10;
  text-align: start;
}
.ozel-haber-detail-badgee-Container{
  position: absolute;
  top: 3%;
  left: 3%;
  z-index: 10;
  text-align: start;
}
.ozel-haber-badgee {
  width: 50px;
  height: auto;
  margin-top:10px;

}
.ozel-haber-detail-badgee {
  width: 70px;
  height: auto;
  margin-top:10px;
  /* border-radius: 50%; */
}
.editor-haber-container{
  width:60px;
  margin:0 0 10px 10px;
  height:100% !important;
  
}
.editor-haber-container .ozel-haber-badgee{
  width:100% !important;
 
}
@media(max-width:768px){
 
  .ozel-haber-container,.ozel-haber-detail-badgee-Container{
    top:1%;
    left:2%;
  }
}