.category-dedecterd-border {
  width: auto;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.category-dedecterd {
  display: flex;
  flex-direction: column;
  width: 97%;
  align-items: end;
  justify-content: end;
  margin-top: 1px;
  position: absolute; /* Üstte göstermek için */
  top: 0;
  right: 0;
  left: 0;

  color: white;
  padding: 5px;
  z-index: 2;
}
.texts {
  background-color: #142745;
  color: white;
  padding: 5px;
  font-size: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.texts-top {
  background-color: #ff0000;
  color: #ff0000;
  width: 70%;
  height: 8%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  box-shadow: #333;
  border: 1.58px solid;
}
