/* Ana konteyner */
.navmobile-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 13px 0; /* Üstten ve alttan 10px padding */
}

.navmobile-container-wrapper-centered {
  width: 88vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navmobile-wrapper {
  width: 100%;
}

/* Layer logosu */
.layer-logo img {
  width: 200px;
  height: auto;
}

/* Header kısmı */
.navmobile-header {
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
}

/* Hamburger ikonu */
.hamburger-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Aktif hamburger ikonu */
.hamburger-icon.active {
  transform: rotate(90deg);
}

/* Arama ikonu */
.search-icon {
  cursor: pointer;
}

/* Mobil Menü */
.mobile-menu {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: -250px; /* Menü başlangıçta sağda gizli */
  width: 250px;
  height: 100%;
  background-color: #142745; /* Yeni arka plan rengi */
  transition: right 0.3s ease;
  z-index: 1000;
}

/* Menü açıldığında sağa gelir */
.mobile-menu.open {
  right: 0;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 50px 0;
}

.mobile-menu li {
  margin: 20px 0;
}

.mobile-menu a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

/* Kapatma butonu */
.nav-mobile-close-btn {
  position: absolute;
  top: 20px;
  right: 0px; /* Buton sol tarafta */
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  width: 40px; /* 40px genişlik */
  height: 40px; /* 40px yükseklik */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Genel başlık stili */
.navmobile-container h2,
.navmobile-container h4,
.navmobile-container p,
.navmobile-container a {
  color: white; /* Beyaz renk */
  font-size: 1.5rem; /* Font boyutu */
  font-weight: 400; /* Font ağırlığı */
  text-transform: capitalize; /* İlk harfi büyük yap */
  text-decoration: none; /* Alt çizgiyi kaldır */
  margin: 0.5rem 0; /* Üst ve alt boşluk */
  font-family: Arial, sans-serif; /* Genel bir font ailesi */
}

/* Dropdown item düzeni */
.navmobile-dropdown-item {
  font-size: 1rem; /* Alt öğeler için daha küçük boyut */
}

/* Navbar linkleri için düzenleme */
.navmobile-dropdown-link {
  display: block;
  padding: 0.5rem 0;
  transition: all 0.3s ease-in-out;
}

.nav-mobile-download-container {
  position: relative;
  width: 300px; /* Kutu genişliği */
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-mobile-download-top-container {
  background-color: #ff0000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.nav-mobile-download-top-close-indir {
  background-color: #142745;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 3px;
}

.nav-mobile-download-top-content {
  color: #f0f0f0;
  display: grid;
  margin: 15px;
  padding: 0;
}

.nav-mobile-download-top-content h1 {
  color: #f0f0f0;
  display: grid;
  margin: 0;
  padding: 0;
  align-items: start;
  justify-content: start;
  font-size: 16px;
}
.nav-mobile-download-top-content p {
  color: #f0f0f0;
  display: grid;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.nav-mobile-download-top-close-button {
  color: #fffafa !important;
  width: 11px;
  height: 11px;
  top: 70px;
  left: 22px;
  gap: 0px;
  opacity: 1;
}

.nav-mobile-download-top-close-indir-buttomn {
  background-color: white;
  border-radius: 20px;
  width: 55px;
  height: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.navmobile-topbar-ad {
  color: white;
  padding: 10px 20px;
}
.button-nav-item{
  all:unset;
  margin-top:20px;
  width:100%;
}
.button-nav-item:hover{
  all:unset;
}