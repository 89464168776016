.Cache-Control-gizlilik-aydinlatma-body {
  align-items: start;
  justify-content: start;
  margin: 0 12vw;
  gap: 15px;
}
.Cache-Control-gizlilik-aydinlatma-left-line {
  margin-top: 20px;
  width: 40%;
  border-bottom: 2px solid #ff0000;
}
.Cache-Control-gizlilik-aydinlatma-titleh3 {
  text-align: left;
  font-weight: 500;
}
.Cache-Control-gizlilik-aydinlatma-link {
  color: black;
}
.Cache-Control-gizlilik-aydinlatma-container {
  display: flex;
  gap:2rem
}

@media (max-width: 768px) {
  .Cache-Control-gizlilik-aydinlatma-container {
    display: grid;
    grid-template-columns: 1fr; /* Tek sütun düzeni */
    gap: 20px;
  }
}
@media (max-width: 1000px) {
  .Cache-Control-gizlilik-aydinlatma-body {
    margin: 0 5vw;
  }
}

.Cache-Control-gizlilik-aydinlatma-content {
  width: 150%;
}
@media (max-width: 768px) {
  .Cache-Control-gizlilik-aydinlatma-content {
    width: 98%; /* Ekran 768px'den küçükse genişlik %100 olacak */
    /*     margin: 0 auto;Ortalamak için margin auto */
  }
}
.Cache-Control-gizlilik-aydinlatma-content .relatedItem{
  height:auto
}
/* .Cache-Control-gizlilik-aydinlatma-side{
  width:88%
} */
