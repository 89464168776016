.Left {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  margin: 1vw 12vw;
}
/* .Left-ad {
  width: 71vw;
} */

.card-container {
  margin-bottom: 40px;
}
.contact-info {
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-info h2 {
  text-align: center;
}
.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card-item {
  flex: 1 1 calc(33.333% - 20px); /* 3 kart yanyana */
  background-color: #f9f9f9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); /* Sadece alt gölge */
  text-align: center;
  border-radius: 10px;
  box-sizing: border-box; /* Padding ve border genişliği içeri dahil */
}

.card-item h4 {
  margin-bottom: 10px;
  color: #ff0000;
}

.kunye-iletisim-bilgileri {
  margin: 8px 0;
  font-size: 1rem;
}
.kunye-iletisim-bilgileri span {
  font-size: 16px;
}
.kunye-iletisim-bilgileri strong {
  margin-right: 15px;
}
.kunye-iletisim-bilgileri a {
  color: black;
  text-decoration: none;
}

.card-header {
  margin: 30px 0 20px;
  font-size: 1.5rem;
  text-align: center;
}

@media (max-width: 1024px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .card-item {
    flex: 1 1 100%; /* Kartlar %100 genişlikte */
  }
  .kunye-side {
    width: 45vw !important;
  }
}

.writers-line {
  border-top: 2px solid #ff0000;
  margin: 25px;
  width: 70%;
  margin-left: -1px;
}

@media (max-width: 768px) {
  .Left {
    display: block;
  }
  .Left {
    margin: 0 3vw !important;
  }
  .Left-ad {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
   

    width: 100%;
  }
  .kunyeTitleBox {
    padding-left: 15px !important;
  }
  /* .card-container,
  .card-header,
  .contact-info {
    padding: 20px;
  } */

  .contact-info {
    margin-bottom: 40px;
    width: 95%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card-container {
    /* width: 80%; */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Tek sütun düzeni */
    gap: 15px; /* Kartlar arasında daha küçük boşluk */
  }

  .card-item {
    flex: 1 1 100%; /* Kartlar %100 genişlikte */
  }

  .card-header {
    font-size: 1.3rem; /* Başlık boyutunu küçülttüm */
  }
  .kunye-side {
    width: 100% !important;
    margin: auto;
  }
}

@media (max-width: 480px) {
  .card-item h3 {
    font-size: 1rem; /* Başlık boyutunu daha da küçülttüm */
  }

  .card-item a {
    font-size: 0.9rem; /* Metin boyutunu küçülttüm */
  }

  .kunye-side {
    width: 15vw;
    margin: auto;
  }
}
@media (max-width: 1000px) {
  .Left {
    margin: 1vw 6vw;
  }
}
.kunye-side{
  width:24vw;
}
.künye-cardItem-person-image {
  margin: 10px 20px;
  width: -webkit-fill-available;
}
.div-kunye-informing-telif-kunye-container {
  justify-content: space-between;
  gap: 30px; /* Aralarındaki boşluğu belirler */
}

.div-kunye-informing-telif {
  flex: 1 1 calc(33.333% - 30px); /* 3 eşit parçaya böl ve gap'ı hesaba kat */
  padding: 15px;
  text-align: center;
  border-radius: 8px;
}
.kunye-telefon-number{
  color:black !important;
}
