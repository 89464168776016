.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  height: auto;
  margin: 5px;
  display: flex;
  /* justify-content: flex-end; */
  position: absolute;
}

.popup-content .relatedImage {
  width: 100%;

  aspect-ratio: 435/470;
  display: block;
  object-fit: cover;
  border-radius: 8px;
}
.close-button-container {
  width:100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
}

.close-button {
  border-radius: 50%;
  font-size: 18px;
  width: 24px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .close-button {
    width: 24px;
    height: 26px;
    font-size: 16px;
  }
}

.popup-content .relatedItem {
  margin-top: 0;
}
