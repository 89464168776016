.dropdown-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ff0000;
}

/* Dropdown container */
.dropdown-container {
  position: relative;
  width: fit-content;
  display: inline-block; /* İçeriği yatayda hizalamak için */
}

/* Dropdown button */
.dropdown-button {
  padding: 10px 20px;
  background-color: #1c293d; /* Mavi arka plan */
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-button:hover {
  background-color: #2a3b55; /* Hover efekti */
}

/* Dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Dropdown menüsünü butonun altında gösterir */
  left: 50%; /* Ortalamak için sol kenara göre yerleştir */
  transform: translateX(-65%); /* Yatayda tam olarak ortala */
  background-color: #142745;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 50;
  display: grid; /* Grid düzeni */
  grid-template-columns: repeat(
    3,
    10fr
  ); /* Varsayılan olarak her satırda 8 sütun */
  gap: 10px; /* Hücreler arası boşluk */
  padding: 10px; /* İç kenar boşluğu */
  width: 1800px;
}

/* Dropdown item */
.dropdown-item {

  text-align: center;
  padding: 10px;
  color: white;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: start;
  display: grid;
}
.button-dropdown-item{
  all:unset !important;
}

.dropdown-item:hover{
  background-color: #2a3b55;
}
.dropdown-items.grid-layout {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 8 sütun */
}

.section-title {
  color: white;
  border-bottom: 2px solid #ff0000;
  width: 80%;
  margin-bottom: 10px;
  padding: 10px;
}

@media (max-width: 1935px) {
  .dropdown-menu {
    width: 1180px;
    transform: translateX(-97.5%); /* Yatayda tam olarak ortala */
  }
}
@media (max-width: 1699px) {
  .dropdown-menu {
    width: 1200px;
    transform: translateX(-96%); /* Yatayda tam olarak ortala */
  }
}
@media (max-width: 1610px) {
  .dropdown-menu {
    width: 1100px;
    transform: translateX(-95%); /* Yatayda tam olarak ortala */
  }
}
@media (max-width: 1453px) {
  .dropdown-menu {
    width: 950px;
    transform: translateX(-98%); /* Yatayda tam olarak ortala */
  }
}

.dropdown-category-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@import url("https://fonts.googleapis.com/css2?family=Carattere&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.titles {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  color: #d82126;
}

.line {
  width: 100%;
  height: 1px;
}
.dropdown-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.dropdown-grid {
  grid-auto-flow: column; /* 8'den fazla olursa yeni sütuna geç */

  display: grid;
  grid-template-rows: repeat(8, auto); /* 8 satır olacak şekilde düzenlendi */
  gap: 10px; /* Satırlar arasında boşluk bırakır */
}
.dropdown-item-statick {
  grid-auto-flow: column; /* 8'den fazla olursa yeni sütuna geç */

  display: grid;
  grid-template-rows: repeat(8, auto); /* 8 satır olacak şekilde düzenlendi */
  gap: 10px; /* Satırlar arasında boşluk bırakır */
}

.dropdown-item-statick div {
  flex: 1 1 100px; /* her bir öğe için genişlik ayarları */
}
