/* Ana kapsayıcı */
.yazarlarimiz-containerr {
  margin: 0 8.2vw !important;
}

/* Yazar bilgileri kısmı */
.main-content {
  width: 90%;
  display: flex;
  justify-content: center;
}

/* Side bileşeninin genişliği ve konumu */

.yazar-wrap {
  width: 130%;
}

/* Yazar resmi ve ismi kısmı */
.yazar-details-image-container {
  display: flex;
  align-items: center; /* Resmin ve ismin dikey hizalanmasını sağladık */
  justify-content: flex-start; /* Resmi sola hizaladık */
  background-color: #ff0000;
  width: 95%;
  height: 50px;
  margin-bottom: 30px;
}

.yazar-name {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center; /* Resmin ve ismin dikey hizalanmasını sağladık */
  justify-content: center; /* Yazının ortalanmasını sağladık */
}

/* Yazarın resminin tam yuvarlak görünmesi için stil */
.yazar-image {
  width: 120px; /* Resmin boyutu */
  height: 120px;
  border-radius: 50%; /* Tam yuvarlak yapmak için */
  object-fit: cover;
  margin-right: 20px; /* Resim ile isim arasındaki boşluğu ayarladık */
  margin-left: -5px; /* Resmi biraz sola kaydırdık */
}

/* Yazarın slug'ı için stil */
.yazar-slug {
  font-size: 1.25rem;
  font-weight: 500;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}

/* Yazı açıklamaları için stil */
.content {
  margin-top: 20px;
}

.title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
}

/* Yazı açıklamaları için stil */
.yazar-description {
  font-size: 1rem;
  margin-top: 15px;
  line-height: 1.5;
  color: #555;
}

/* Açıklama konteynerinin düzenlenmesi */
.description-container {
  margin-top: 20px;
}

/* Banner görseli için stil */
.banner-container {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.banner-image {
  width: 100%;
  height: 200px; /* Yükseklik isteğe göre ayarlanabilir */
  object-fit: cover;
}
/* Banner görselinin sabitlenmesi için güncellenmiş stil */
.banner-container {
  top: 0; /* Sayfanın üst kısmına yerleştirir */
  left: 0;
  width: 100%;
  height: 140px; /* Yükseklik isteğe göre ayarlanabilir */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.banner-image {
  width: 90%;
  height: 100%; /* Banner'ın tüm yüksekliğini kapsar */
  object-fit: cover;
}

.maincontent-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
@media (max-width: 960px) {
  .yazrlarimiz-container {
    display: grid;
    grid-template-columns: 1fr; /* Tek sütunlu grid yapısı */
    gap: 20px; /* Hücreler arasındaki boşluk */
  }

  .side {
    width: 100%; /* Side tam genişlikte olacak */
  }

  .main-content {
    display: block; /* Yatay hizalamayı kaldır, dikeyde sıralama */
  }

  .yazar-wrap {
    width: 100%;
  }
  .yazar-details-image-container {
    display: flex;
    width: 100%;
  }
  .HaberCardDetails {
    width: 100% !important;
  }
  .yazar-name {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.HaberCardDetails {
  width: 95%;
  
}

@media (max-width: 768px) {
  .yazarlarimiz-containerr {
    margin: 0 !important;
  }
}
