/* Genel Stil */
.HaberCardDetails-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.HaberCardDetails-grid {
  display: grid;
  grid-template-columns: 1.2fr 1.3fr; /* Görseli büyük tut */
  gap: 10px;
  max-width: 1200px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 300px; /* Kartın maksimum yüksekliği */
}

/* Görsel Alanı */
.HaberCardDetails-img {
  width: 100%;
  height: 100%; /* Tüm yükseklikten faydalan */
  max-height: 300px; /* Maksimum yükseklik */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position:relative;
}

.HaberCardDetails-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Görseli kapsayacak şekilde ölçeklendir */
}

/* Yazı Alanı */
.text-info-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  max-height: 300px;
  overflow: hidden;
}

.text-info-main-container-desp h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 2 satırdan sonra kes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-info-main-container-desp p {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 2 satırdan sonra kes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Bilgi Alanı */
.info-wrapper {
  margin-top: 10px;
}

.info {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.info-item-main-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #444;
  background: #f1f1f1;
  padding: 6px 8px;
  border-radius: 4px;
}

/* Daha Fazla Butonu */
.info-item-more-button {
  width: auto;
  padding: 8px;
  font-size: 14px;
  background: linear-gradient(to bottom right, red, darkred);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.info-item-more-button:hover {
  background: linear-gradient(to bottom right, red, darkred);
}
@media (max-width: 1000px) {
  .HaberCardDetails-grid {
    grid-template-columns: 1fr; /* Tek sütuna geç */
    max-height: none; /* Yükseklik sınırını kaldır */
  }

  .img {
    height: 250px; /* Daha uygun bir yükseklik belirle */
    max-height: none;
  }

  .text-info-main-container {
    max-height: none;
    padding: 15px;
  }

  .text-info-main-container-desp h2 {
    font-size: 16px;
  }

  .text-info-main-container-desp p {
    font-size: 13px;
  }
}

.info-item-more-button-wrapper {
  width: 100%;
  align-items: center;
  justify-content: end;
  display: flex;
}
.haber-card-details-cards {
  display: flex;
  gap: 20px;
  flex-direction: column;
}


.haber-card-details-pagination-container{
  margin-top:2rem;
  text-align: center;
  display:flex;
  justify-content:center;
 

}
.haber-card-details-pagination-wrapper{
 flex-direction: row; display:flex;  align-items: center;
}
.haber-card-details-pagination-button,.dots{
  padding: 6px 12px;
  margin: 0 4px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}
.haber-card-details-info-sub-header{

   font-size:clamp(.7125rem, .85rem, 0.9rem);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;
padding-top:1rem;
}