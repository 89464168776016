.Cerezler-Left {
  display: flex;
  justify-content: space-between; /* Elemanlar arasına boşluk bırakır */
  align-items: flex-start; /* Elemanları yukarı hizalar */
}

.Cerezler-Left-ad {
  width: 70%; /* İçeriğin genişliği %70 */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cerez-side {
  width: 30%; /* Yan taraftaki bölümün genişliği %30 */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Cerezler-left-line {
  margin-top: 20px;
  width: 40%;
  border-bottom: 2px solid #ff0000;
}

.Cerez-Title {
  margin-bottom: 30px;
}

.Cerez-Text a {
  color: #121212;
  text-decoration: none;
}

.Cerezler-left-h1 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.Cerezler-Left-content {
  margin-top: 20px;
}

.Cerezler-left-subtitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.Cerezler-left-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  font-size: 1rem;
  color: #121212;
}

.Cerez-Container {
  display: flex;
  margin: 15px 11vw;
  gap: 20px; /* Elemanlar arasında boşluk bırakmak için */
}

.Cerez-Content {
  width: 70%; /* İçeriğin genişliği %70 */
  margin: 30px 0;;
}

@media (max-width: 768px) {
  .Cerez-Container {
    display: grid;
    grid-template-columns: 1fr; /* Tek sütun düzeni */
    gap: 20px; /* Grid düzenindeki boşluk */
    margin: 15px 5vw;
  }

  .Cerez-Content {
    margin: auto;
    width: 100%;
  }

  .Cerez-Section {
    font-size: small;
    width: 90%;
  }

  .cerez-side {
    width: 100%; /* Yan taraftaki bölüm mobilde tam genişlik alacak şekilde ayarlandı */
  }
}

@media (max-width: 350px) {
  .Cerez-Section {
    width: 80%;
  }
}
@media(max-width:1000px){
  .Cerez-Container{
    margin:0 5vw;
  }
  .Cerez-Content{
    margin:30px 0;
  }
}
