.Topbar {
  background-color: #142745; /* Arka plan rengi */
  padding-top: 4px; /* İçerik ile kenarlar arasına boşluk */
  padding-bottom: 4px; /* İçerik ile kenarlar arasına boşluk */
  display: flex;
  width: 74.5vw;
  margin-left: 0;
}

.Topbar ul {
  list-style-type: none; /* Liste noktalarını kaldır */
  margin: 0;
  padding: 0;
  display: flex; /* Öğeleri yatay sırala */
  gap: 10px; /* Öğeler arası boşluk */
  align-items: center;
}

.Topbar li {
  font-size: 0.8rem !important; /* Yazı boyutunu büyüttüm */
  font-weight: 500; /* Kalın yazı */
  /*text-transform: uppercase;  Büyük harf yap */
  border-right: 2px solid gray;
  position: relative; /* Dropdown menüsü için pozisyon */
  white-space: nowrap; /* Yazı taşmasını engelle */
}

.Topbar a {
  text-decoration: none; /* Alt çizgiyi kaldır */
  color: #ffffff; /* Beyaz yazı rengi */
  padding: 4px 15px; /* İçerik ile kenarlar arasında boşluk */
  border-radius: 5px; /* Köşeleri yuvarla */
  transition: all 0.3s ease; /* Hover için yumuşak geçiş */
}

.Topbar .dropdown:hover .dropdown-content {
  display: block; /* Dropdown menüsünü göster */
}

.Topbar .dropdown-content {
  display: none; /* Başlangıçta gizle */
  position: absolute; /* Konumlandır */
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Topbar .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.Topbar .dropdown-content a:hover {
  background-color: #ddd;
}

/* --- Duyarlı Tasarım --- */

@media (max-width: 1610px) {
  .Topbar a {
    text-decoration: none;
    color: #ffffff;
    padding: 4px 10px;
    border-radius: 5px;
    transition: all 0.3sease;
  }
}
@media (max-width: 1453px) {
  .Topbar a {
    text-decoration: none;
    color: #ffffff;
    padding: 4px 5px;
    border-radius: 5px;
    transition: all 0.3sease;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

.Topbar ul li {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(0.7125rem, 1vw, 0.9rem);
  font-weight: 400;
}
 .button-menu-link{
  all:unset;
font-family: "Montserrat", sans-serif;
font-size: 0.8rem !important; 
font-weight: 400; 
border-right: 2px solid gray;
position: relative;
white-space: nowrap; 
color: #ffffff;
padding:0  15px;

}
 .button-menu-link:hover{
  all:unset;
font-family: "Montserrat", sans-serif;
font-size: 0.8rem !important; 
font-weight: 400; 
border-right: 2px solid gray;
position: relative;
white-space: nowrap; 
color: #ffffff;
padding:0 15px
}  