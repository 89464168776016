.haber-details-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* max-width: 1700px; */
  /* margin: 20px auto;  */
  margin: 0 12vw;
  gap: 3rem;
  margin-top: 1rem;
  /* margin-left: 5%; */
}
.haber-details-content .haber-details-container-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.haber-details-kibrisli-news {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: max-content;
}
.haber-details-content {
  flex: 7;
  padding: 0 !important;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* İçeriği yatayda ortalar */
  text-align: left; /* Metni ortalar */
  max-width: 1000px; /* Maksimum genişlik 1000px */
  margin: 0 auto; /* Ortalamak için */
}

.haber-details-content h1,
.haber-details-content h3,
.haber-details-content p,
.haber-details-content div {
  /* min-width: 90%; Genişlik %90 olacak */
  align-self: stretch;
  height: auto;
  /* max-width: 75%;  Taşma olmaması için */
  text-align: left; /* Metinleri ortalar */
}
.haber-details-content img {
  align-self: normal;
}

.haber-details-content p {
  margin-bottom: 20px; /* Paragraflar arasında boşluk */
}

.haber-details-side-wrapper {
  flex: 3;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .haber-details-container {
    display: grid;
    grid-template-columns: 1fr; /* Tek kolonlu grid düzeni */
    gap: 15px; /* Aralarındaki boşluğu azalt */
    margin: 0 5vw;
  }
  .haber-details-side-wrapper .Sidevar {
    padding: 0 !important;
  }
  .haber-details-side-wrapper .Sidevar {
    width: auto;
  }
  .haber-details-container .haberContent iframe {
    width: 90vw !important;
  }
}

.image-caption {
  text-align: center;
  font-size: 17px;
  color: #555;
}

.social-media-icons {
  display: flex;
  justify-content: flex-start; /* Sola hizalama */
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px; /* Yuvarlak yapmak için genişlik */
  height: 27px; /* Yuvarlak yapmak için yükseklik */
  border-radius: 50%; /* Yuvarlak şekil */
  color: white; /* İkonların rengi */
  transition: background-color 0.3s ease;
}

.line {
  width: 100%;
  border-bottom: 2px solid #d82126; /* Alt çizgi */
  margin-top: 20px;
}
.social-icon.facebook {
  background-color: #3b5998; /* Facebook renk */
}

.social-icon.instagram {
  background-color: #e4405f; /* Instagram renk */
}

.social-icon.youtube {
  background-color: #ff0000; /* YouTube renk */
}

.social-icon.twitter {
  background-color: #1da1f2; /* Twitter renk */
}

.haber-details-image-fyi {
  display: flex;
  flex-direction: row !important;
  gap: 8px;
  height: 46px !important;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.haber-details-image-editor-logo {
  width: 60px !important;
  height: 30px !important;
  object-fit: contain;
  border-radius: 0%;
}
.haber-details-editor-name {
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  color: #868686;
}

.haber-tags {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 1vw;
  height: 100%;
}
.haber-tags h4 {
  margin: 0 5px 0 0;
  color: #222;
  margin-top: 2px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-width: auto !important;
  font-size: 0.9rem;
}

.tag {
  color: #ff0000;
  padding: 2px 0px;

  font-weight: 500;
}

.haber-details-content .haber-details-Related-component .relatedImage {
  aspect-ratio: 8/1 !important;
  width: 100%;
  max-width: 100% !important;
}

.haber-details-image-wrapper {
  width: 100%;
  margin-top: 0.5rem;
  position: relative;
}
.HaberIcı-Alt-Related-container {
  max-width: 100% !important;
  height: 100% !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding: 0 !important;

  min-width: none !important;
}
.HaberIcı-Alt-Related-container .relatedItem .relatedImage {
  aspect-ratio: 728 / 90 !important;
  max-width: 100%;
  /* width: auto; */
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.news-loading-container,
.news-error-container {
  padding: 2rem;
  text-align: center;
  background: #f5f5f5;
  border-radius: 8px;
  margin: 1rem 0;
}

.news-loading-container p,
.news-error-container p {
  font-size: 1.1rem;
  color: #666;
}
