/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 50vw;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  margin: 20px;
  justify-content: center;
  display: flex;
}

/* Modal Content */
.modal-image-content {
  background-color: white;
  justify-self: center;
  display: flex;
  width: 95%;
  max-height: 65vh; /* İçerik çok uzarsa kaydırma için maksimum yükseklik */
  overflow-y: auto; /* Yalnızca içerik dikey kaydırılabilir */
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* Ensure modal image wrapper and other elements respect the scroll behavior */
.modal-image-wrapper {
  width: 80%;
  margin-bottom: 20px;
  justify-self: center;
}
.modal-image-wrapper img{
  width:100%;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
}

.close-btn:hover {
  background-color: #e55e54;
}

h1 {
  justify-self: center;
  padding: 20px 0;
  margin: 0;
}

/* Title */
h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Modal Image Container */
.modal-image-container {
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  height: 100%;
  background-color: black;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width:100%;
}

.modal-close-btn {
  position: absolute;
  top: -20px;
  right: -15px;
  width: 40px;
  height: 40px;
  background-color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1001;
}

.testttttt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.modal-image-content h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.modal-image-dates {
  background-color: black;
  color: white;
  padding: 10px 0;
  font-family: sans-serif;
}
.modal-center {
  padding: 0 15px;
}
/* Main Content */
.modal-center-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-center-main strong,
.modal-center-main p {
  color: black;
}

.modal-center p {
  font-size: 18px;
  margin: 15px 0;
}

.strong-div {
  font-weight: bold;
}

@media (max-width: 768px) {
  .modal {
    width: 80vw;
  }
}
