.yayinIlkeleri-Left {
  display: flex;
  align-items: start;
  justify-content: start;
  margin: 1vw 12vw;
  gap: 15px;
}

.yayinIlkeleri-Left-ad {
  width: 130%;
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}

.yayinIlkeleri-Left-content {
  margin-top: 20px;
}
.yayinIlkeleri-Left-h1 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.yayinIlkeleri-Left-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  font-size: 1rem;
  color: #121212;
}

@media (max-width: 1000px) {
  .yayinIlkeleri-Left {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 5vw;
  }
  .yayinIlkeleri-Left-ad {
    width: 100%; /* Ekran 768px'den küçükse genişlik %100 olacak */
  }
}
@media (max-width: 768px) {
  .yayinIlkeleri-Left-ad {
    width: 90%; /* Ekran 768px'den küçükse genişlik %100 olacak */
    margin: auto; /* Ortalamak için margin auto */
  }
}
