.Muhabirler-page {
    margin: 0 12vw;
  }
  
  .Muhabirler-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
  }
  
  .Muhabirler-list {
    flex: 4 1;
    min-width: 300px; /* Prevent it from getting too small */
  }
  
  .Muhabirler-side {
    flex: 1.5; /* Increase the flex value to make it larger */
  }
  .deneme .ad-item .ad-link .ad-image {
    height: 170px;
  }
  
  .Muhabirler-cell {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default: 3 columns */
    gap: 20px; /* Add gap between cards */
  }
  
  .Muhabirler {
    background-color: #d9d9d940;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    backdrop-filter: blur(4px);
  }
  
  .Muhabirler:hover {
    transform: translateY(-10px);
  }
  
  .Muhabirler-buttons-wrappers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Muhabirler-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .Muhabirler h2 {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  
    color: #d82126;
  }
  
  .Muhabirler p {
    margin-top: 5px;
    color: #0a1b36;
  }
  
  .item {
    margin-top: 10px;
    color: #555;
  }
  
  .btn-blue {
    width: 60%;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #c2c2c24a;
    color: #202020;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  @media (max-width: 1380px) {
    .Muhabirler-cell {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }
    .Muhabirler-list {
      flex: 3 1;
    }
  }
  
  @media (max-width: 1150px) {
    .Muhabirler-cell {
      grid-template-columns: repeat(1, 1fr); /* 2 columns on smaller screens */
    }
    .Muhabirler-list {
      flex: 1.5 1;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .Muhabirler-cell {
      grid-template-columns: repeat(1, 1fr); /* 2 columns on smaller screens */
    }
  
    .Muhabirler-list {
      flex: 1 1 100%; /* Full width on smaller screens */
    }
  
    .Muhabirler-side {
      flex: 1 1 100%; /* Full width on smaller screens */
      max-width: none; /* Allow it to shrink */
    }
  
    .container {
      padding: 10px;
    }
    .Muhabirler-page {
      margin: 0;
    }
  }
  
  @media (max-width: 480px) {
    .Muhabirler-cell {
      grid-template-columns: 1fr; /* 1 column on very small screens */
    }
  
    .Muhabirler h2 {
      font-size: 1rem; /* Smaller font size on very small screens */
    }
  
    .Muhabirler p {
      font-size: 0.9rem; /* Smaller text for better readability */
    }
  
    .btn-blue {
      width: 100%; /* Full width for buttons on very small screens */
    }
  }
  