.currency-ticker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.currency-ticker-logo img {
  max-width: 100px;
}

.currency-ticker {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.currency-ticker-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 65px;
}

.currency-ticker-item {
  display: flex;
  align-items: center;
  justify-content: space-between; /* İkonları sola hizaladık */
  background-color: #f5f5f5;
  min-height: 65px;
  border-bottom: 1px solid gray;
}

.currency-ticker-item img {
  width: 25px;
  height: 25px;
}

.currency-ticker-item span {
  font-size: 16px;
  font-weight: bold;
}

.currency-price {
  font-size: 14px;
  color: #333;
}

.currency-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
  width: 3rem;
  height: 3rem;
  border-radius: 8px; /* Kenarları yuvarlatmak için */
}

.currency-icon {
  font-size: 30px; /* İkon boyutunu istediğiniz gibi ayarlayabilirsiniz */
}
