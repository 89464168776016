/* CategoryPage.css */
.category-page-titleBox {
  padding: 0 12vw;
}

.catgeoryAd-article-card {
  cursor: pointer;
  height:100%;
  position: relative;
}
/* Genel Kategoriler Sayfası */
.category-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 12vw;
}
@media (max-width: 1000px) {
  .category-page {
    padding: 0 5.3vw;
  }
}

.catgeoryAd {
  flex: 7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* İçeriği yatayda ortalar */
  text-align: left; /* Metni ortalar */
  margin: 0 auto; /* Ortalamak için */
}

/* Başlık ve Kategori Resmi
.category-header {
  flex: 3;
  padding: 20px;
  background-color: #f4f4f4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
} */
/* 
.category-title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
} */

/* .category-image {
  width: 100%;
  height: auto;
  object-fit: cover;
} */

/* Makaleler */
.catgeoryAd-articles {
  flex: 2;
  width: 100%;
  margin-top: 20px;
}

.catgeoryAd-articles ul {
  list-style-type: none;
  padding: 0;
}

.article {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.article h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.article p {
  font-size: 16px;
  color: #555;
}

.catgeoryAd-article-image{
  width: 100%;
  height: 80%;
  object-fit: cover;
}

/* Side (Yan Panel) */
.Sidevar {
  flex: 1;
  padding: 20px 20px 0 20px; /* Üst: 20px, Sağ: 0px, Alt: 20px, Sol: 20px */

  border-radius: 10px;
}

.Table1Wrapper,
.Table2Wrapper {
  margin-bottom: 30px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

button {
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  transition: all 0.3s ease;
}

button:hover {
  background: linear-gradient(to bottom right, red, darkred);
}
button img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

button div {
  flex: 1;
}

button div h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

button div p {
  font-size: 14px;
  color: #777;
}

/* Responsive tasarım: Ekran küçüldükçe kartlar alt alta sıralanır */
@media (max-width: 800px) {
  .addCategory {
    padding: 0 5vw !important;
  }
  .category-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0 2vw !important;
  }
  .category-page-titleBox {
    padding: 0 5vw;
  }
  .catgeoryAd {
    padding: 0 3vw;
  }
  .catgeoryAd-article-title {
    font-size: 15px;
    text-align: left !important;
  }
}


  @media (max-width: 410px) {
    .catgeoryAd-articles-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr) !important; /* 2 sütun oluşturur */
      gap: 20px; /* Hücreler arasındaki boşluk */
      max-width: 100%; /* Max genişlik */
    }
  }

.catgeoryAd-articles-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 sütun oluşturur */
  gap: 20px; /* Hücreler arasındaki boşluk */
  max-width: 100%; /* Max genişlik */
  margin-top:20px;
}

.article-item {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
h3 {
  font-size: 14px !important;
  text-align: left !important;
}

.category-page-more-news {
  justify-self: center;
  margin: 40px 30px;
}

@media (min-width: 768px) and(max-width:1150px) {
  .category-page-more-news button {
    margin: 50px 50px !important;
  }
}

.catgeoryAd-article-title{
  height:15%;
}
@media (max-width: 768px) {
  .catgeoryAd-articles-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}