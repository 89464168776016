.Vefatlarbottom {
  padding: 0 12vw;
  margin: auto;
  display: flex;
  gap: 10px;
}
.vefatlar-left-side-container {
  width: 130%;
}
.Vefatlar-table-container {
  flex: 3;
  overflow-x: auto;
}
.Vefatlar-container {
  width: 100%;
}

.vefatlar-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", "Open Sans", Arial,
    sans-serif;
}

.vefatlar-table th,
.vefatlar-table td {
  padding: 12px 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.vefatlar-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.vefatlar-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.vefatlar-table tr:hover {
  background-color: #f1f1f1;
}


.Vefatlar-container-first-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  height: fit-content;
  table-layout: fixed;
}
.searchbar-vefatlar {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 95%;
  height: 5%;
}
.search-input {
  width: 40%;
  padding: 0.7rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.search-button {
  background-color: #d82126;
  color: white;
  border: none;
  padding: 0.7rem 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 60px;
}
.searchbar-vefatlar-item-one {
  font: bold;
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  letter-spacing: 0%;
}
.searchbar-vefatlar-item-two {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  justify-content: right;
  width: auto;
}
@media (max-width: 768px) {
  .Vefatlarbottom {
    display: list-item;
    padding: 0 5vw;
    width: auto;
  }
  .searchbar-vefatlar {
    margin: 20px 0;
  }
  .vefatlar-left-side-container {
    justify-self: center;
  }
  .vefatlar-left-side-container {
    width: 100%;
  }
  .Vefatlar-sidebar .Sidevar {
    padding: 20px 0;
  }
}
@media (max-width: 500px) {
  .vefatlar-table td {
    font-size: 13px;
    padding: 10px 2px;
  }
  .vefatlar-table {
    table-layout: auto;
    font-family: Arial, sans-serif;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .vefatlar-table td {
    padding: 10px 5px;
  }
  .Vefatlar-sidebar .Sidevar {
    padding-right: 0 !important;
  }

  .Vefatlarbottom {
    padding: 0 5vw;
  }
  .addCategory {
    padding: 0 5vw !important;
  }
}
