.special-id-category-slider-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.special-id-category-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 440px;
  width: 100%;
}

.special-id-category-slider-wrapper {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.special-id-category-slider-item {
  flex: 0 0 auto; /* Prevent items from growing or shrinking */
  width: 100%; /* Full width of the container */
  position: relative; /* For absolute positioning of text */
}

.special-id-category-slider-image-container {
  width: 100%;
  height: 100%; /* Sabit yükseklik veriyoruz */
  overflow: hidden; /* Taşan alanları gizle */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 769px) {
  .special-id-category-slider {
    height: 250px;
  }
}
@media (max-width: 450px) {
  .special-id-category-slider {
    height: 230px;
  }
}
@media (min-width: 768px) and (max-width: 1050px) {
  .special-id-category-slider {
    height: 280px;
  }
}
.special-id-category-slider-image {
  width: 100%; /* Konteynerin tamamını kaplasın */
  height: 100%; /* Konteynerin yüksekliği kadar olsun */
  object-fit: fill; /* Görüntüyü kırparak tam oturt */
}

.special-id-category-slider-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 1.5em;
  background-color: rgba(28, 41, 61, 0.7);
  margin: 0;
}

.special-id-category-buttons {
  position: absolute;
  bottom: 1px;
  width: 100%;
  padding: 0 20px;
  color: white;
}

.special-id-category-prev-btn,
.special-id-category-next-btn {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
}

.renderPageNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.special-id-category-page-btn {
  color: white;
  background-color: #07152c;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.special-id-category-page-btn {
  color: white;
  background-color: #07152c;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.special-id-category-page-btn:hover,
.special-id-category-page-btn:focus,
.special-id-category-page-btn:active {
  background-color: #d82126;
  color: white;
}

.special-id-category-r-btn {
  color: #ff0000;
  background-color: #07152c;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.special-id-category-r-btn:hover,
.special-id-category-r-btn:focus,
.special-id-category-r-btn:active {
  background-color: #d82126;
  color: white;
}
/*  */

.special-id-category-slider-overlay-test {
  border-left: 2px solid #ff0000; /* Sol tarafta kırmızı çizgi */
  display: flex;
  padding: 5px;
  font-size: 1.5em;

  max-width: calc(100% - 20px); /* Yüksekliği aşmamak için genişliği ayarla */
}

.special-id-category-slider-title {
  position: absolute;
  bottom: -1px; /* Başlık altta olacak şekilde yerleşim */
  left: -1px;
  background-color: rgba(28, 41, 61, 0.7);
  color: white; /* Yazı rengi beyaz */
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Gölgeleme efekti */
  transition: background-color 0.3s, transform 0.3s; /* Hover efekti için geçiş */
  z-index: 2;
  width: 100%;
  word-wrap: break-word; /* Uzun yazıları alt satıra geçirecek */
  max-width: calc(100% - 20px); /* Yüksekliği aşmamak için genişliği ayarla */
}

.special-id-category-slider-title h3 {
  font-size: 1.5em;
  line-height: 1.4;
  text-align: center;
}

.special-id-category-slider-item .relatedItem {
  height: 100% !important;
  margin-top: 0 !important;
}

.special-id-category-slider-item .relatedItem img {
  object-fit: fill !important;
  contain: size;
  height: 100%;
  aspect-ratio: 810/522;
}
.category-slider-default-img {
  width: 100%;
  height: 100%; /* Sabit yükseklik veriyoruz */
  overflow: hidden; /* Taşan alanları gizle */
  display: flex;
  justify-content: center;
  align-items: center;
}
