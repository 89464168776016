.Footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: aqua;
  margin-top: 25px;
}

.footer-top {
  background: linear-gradient(to bottom right, red, darkred);
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 10px;
}

.footer-center {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
}

.social-media-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-media-icons {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.footer-social-icon {
  background-color: #142745;
  transition: background-color 0.3s ease;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-icon a {
  color: white;
  text-decoration: none;
}
.footer-download-socail-media {
  flex-direction: row;
  display: flex;
}

.app-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appstore-link {
  display: flex;
  margin-left: 10px;
  gap: 10px;
}

.footer-main {
  background-color: #142745;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: #d8212659 2px solid;
  padding: 5vh 0;
}

.footer-main-center {
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.footer-main-logo {
  display: flex;
  flex-direction: column; /* Elemanları alt alta yerleştirir */
  align-items: center; /* Elemanları yatayda ortalar */
  text-align: center; /* Metni ortalar */
}

.Layer-img {
  margin-bottom: 10px; /* Görsel ile metin arasına boşluk ekler */
}

.footer-text {
  color: white;
  font-size: 16px;
}
.menu-item a {
  font-size: 14px;
}
.menu-grid {
  display: grid; /* Grid düzenini kullan */
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Her satırda 4 sütun olacak şekilde düzenle */
  gap: 10px; /* Elemanlar arasında boşluk */
  list-style: none; /* Liste işaretlerini kaldır */
  padding: 0; /* Varsayılan padding'i sıfırla */
  /*  margin: 0; Varsayılan margin'i sıfırla */
  margin: 0 2vw;
}

.menu-item {
  color: white;
  /* font-family: Inter; */
  font-size: 20px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
@media (max-width: 768px) {
  .menu-item {
    font-size: 16px;
  }
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  background-color: #142745;
}

.footer-bottom-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.line-before {
  display: flex;
  height: 65px;
  border-left: 1px solid #d82126;
  gap: 15px;
  margin: 5px;
}

.footer-bottom-center p {
  color: white;
  margin: 0; /* Paragrafların arasındaki varsayılan boşluğu kaldırır */
}

.line-before {
  color: white;
  border-left: 3px solid #ff0000;
  height: 40px;
  margin-right: 10px;
  align-self: stretch; /* Çizginin yüksekliğini tüm elemanlarla hizalar */
}

/* İkinci paragrafın daha küçük olmasını sağlamak */
.footer-bottom-center p:nth-of-type(2) {
  color: white;
  max-width: 900px; /* İkinci paragrafın alanını kısıtlar */
  word-wrap: break-word; /* Uzun kelimelerin bir sonraki satıra geçmesini sağlar */
}
@media (max-width: 760px) {
  .footer-main-center {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
  .footer-main-logo {
    display: flex;
    align-items: start;
    width: 100%;
    margin-top: 10px;
  }

  .footer-main {
    height: fit-content;
  }

  .Footer {
    height: fit-content;
  }

  .menu-grid {
    flex-direction: column;
    height: fit-content;
    align-items: center;
    gap: 0 10px;
  }

  .footer-bottom-center {
    height: fit-content;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .line-before {
    display: none;
  }

  .footer-bottom {
    height: fit-content;
  }

  .footer-contant {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80%;
  }
  /* 
  .social-media-text {
    display: none;
  } */

  .footer-social-icon {
    width: 30px;
    height: 30px;
    padding: 5px;
  }

  .appstore-link img {
    width: 100px;
    height: 39px;
  }
}
@media (max-width: 450px) {
  .menu-grid .menu-item {
    font-size: 12px; /* Daha küçük bir font boyutu */
    line-height: 15px; /* Daha küc��ük bir satır boyu */
    padding: 5px 0;
    align-self: start;
  }
  .footer-social-icon {
    width: 15px;
    height: 15px;
    padding: 5px;
  }
  .appstore-link {
    align-items: center;
    justify-content: center;
  }
  .appstore-link img {
    width: 60px;
    height: 25px;
  }
  .app-text {
    font-size: 11px;
  }
}

.menu-item a {
  color: white;
  text-decoration: none;
}

.scroll-to-top {
  position: fixed; /* Sabit konum */
  bottom: 7vh;
  left: 20px; /* Sayfanın sağ kısmına yakın */
  width: 40px;
  height: 40px;
  background-color: #cb0e0e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-size: 20px;
  cursor: pointer;
  border-radius: 0 !important;
  border-color: #cb0e0e;
}
.scroll-fixed-socail-icons {
  position: fixed;
  bottom: 7vh;
  right: 0;
  flex-direction: column;
  background-color: #ff0000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 1000;
  font-size: 15px;
  cursor: pointer;
}
.footer-fixed-social-icon {
  background-color: #ff0000;
  transition: background-color 0.3s ease;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-fixed-social-icon a .footer-fixed-social-icon-claretred a {
  color: white;
  text-decoration: none;
}

.footer-fixed-social-icon-claretred {
  background-color: #cb0e0e;
  transition: background-color 0.3s ease;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-proveredby {
  background-color: #142745;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: fit-content; /* İçeriğe göre büyür */
  max-height: 200px; /* 200px'den büyük olamaz */
}

.footer-proveredby-centered {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 70px;
}

.footer-proveredby-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 10px;
}

.footer-proveredby-wrapper-line {
  width: 2px;
  height: 60px;
  background-color: #d8212659;
}

.footer-proveredby-wrapper h1,
.footer-proveredby-wrapper p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  letter-spacing: 0%;
}

.footer-proveredby-wrapper p {
  max-width: 800px;
  word-wrap: break-word;
  font-weight: 300;
}

.footer-proveredby p {
  font-size: 12px;
}

.footer-proveredby a h1 {
  font-size: 12px;
}

.footer-proveredby-wrapper a {
  text-decoration: none;
}

/* 920px'den küçük ekranlarda margin-left ayarı */
@media (max-width: 920px) {
  .footer-proveredby-centered {
    margin-left: 30px;
  }
}

/* 600px'den küçük ekranlarda her şey alt alta gelsin */
@media (max-width: 600px) {
  .footer-proveredby-centered {
    flex-direction: column; /* İçerikleri dikey hizala */
    align-items: center; /* Ortala */
    text-align: center; /* Yazıları ortala */
    padding: 5px 55px;
  }

  .footer-proveredby-wrapper {
    flex-direction: column; /* h1, çizgi ve p alt alta */
    gap: 10px; /* Daha küçük boşluk */
    text-align: center;
    margin: 0;
  }

  .footer-proveredby-wrapper-line {
    height: 2px; /* Çizgiyi yatay hale getir */
    width: 100%; /* Genişliği ayarla */
  }
  .footer-proveredby-centered {
    margin-left: 0px;
    width: 100%;
  }

  .footer-proveredby {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-proveredby-wrapper {
    display: flex;
    flex-direction: column;
  }

  .footer-proveredby-wrapper h1 {
    order: 2; /* h1 en alta gider */
  }

  .footer-proveredby-wrapper .footer-proveredby-wrapper-line {
    order: 1; /* Çizgi olduğu yerde kalır */
  }

  .footer-proveredby-wrapper p {
    order: 0; /* p en üste gider */
  }
}
