.aydınlatma-first {
  margin-left: 15px;
}

.aydinlatma-Left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  margin: 0 12vw;
}

.aydinlatma-Left-ad {
  width: 100%;
  display: flex;
  gap:2rem;
}

@media (max-width: 768px) {
  .aydinlatma-Left-ad {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}



.aydinlatma-Left-section {
  margin-top: 20px;
}
.aydinlatma-link {
  color: #121212;
}
.aydinlatma-left-line {
  margin-top: 20px;
  width: 40%;
  border-bottom: 2px solid #ff0000;
}

.aydinlatma-Title {
  margin-bottom: 40px;
}

.aydinlatma-left-h1 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.aydinlatma-left-subtitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.aydinlatma-left-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  font-size: 1rem;
  color: #121212;
}

/* stun */

/* Ana kapsayıcı */
.aydinlatma-aydınlatma-container {
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 15px;
  height: fit-content;
}

/* Satırları düzenler */
.aydinlatma-aydınlatma-row {
  display: flex;
}

/* Hücreleri düzenler */
.aydinlatma-aydınlatma-cell {
  flex: 1;
  height: 100%;
  padding: 10px;
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: white;
  border: #121212 1px solid;
}

.aydinlatma-aydınlatma-row {
  width: 10%;
}

.aydinlatma-aydınlatma-row2 {
  display: grid;
}

.aydinlatma-aydınlatma-row3 {
  width: 100%;
  max-width: 750px;
}
.aydinlatma-aydınlatma-row4 {
  display: grid;
  width: 100%;
  max-width: 750px;
}
/* @media (min-width: 768px) {
  .aydinlatma-side {
    margin-right: 5vw;
  }
} */

.aydinlatma-Left-content {
  width: 150%; /* Mobilde genişlik %150 */
}

@media (max-width: 768px) {
  .aydinlatma-Left-content {
    width: 100%; /* 768px'den küçük ekranlarda genişlik %80 */
    margin: 0 auto;
  }

  .aydinlatma-Left {
    margin: 0;
  }
}

.aydinlatma-left-RelatedComponent {
  height: auto;
}
@media(max-width:1000px){
  .aydinlatma-Left {
   
    margin: 0 5vw;
  }
}
